/* eslint-disable max-classes-per-file */

import { isDevelopment } from './helpers';

// Вызывать в случае, если игнорирование ошибки является безнес логикой
export function ignoreError(...args) {
  if (isDevelopment) {
    console.error('Ignored error: ', ...args);
  }
}

// Вызывать в случае, если ошибка не обрабатывается, но по-хорошему должна
export function lazilyHandleError(...args) {
  console.error('Error: ', ...args);
}

// Вызывать в случае, если ошибка обрабатывается, но мы все-равно хотим о ней узнать
export function logError(...args) {
  console.error('Loged error: ', ...args);
}

export class FetchError extends Error {
  constructor({ error, retries }) {
    super(`Fetch error after ${retries} retries.`);
    this.error = error;
    this.retries = retries;
  }
}

export class HttpError extends Error {
  constructor({ response }) {
    super(response.statusText || 'Http error.');
    this.response = response;
  }
}

export class MappingError extends Error {
  constructor({ reason, originalValue }) {
    let reasonDetails = '';
    if (reason) {
      reasonDetails = ` ${reason}.`;
    }

    let originalValueDetails = '';
    if (originalValue) {
      try {
        originalValueDetails = ` Origial value: ${JSON.stringify(originalValue)}.`;
      } catch (error) {
        lazilyHandleError(error);
      }
    }

    super(`Can't finish mapping.${reasonDetails}${originalValueDetails}`);
  }
}
