import isMobile from 'ismobilejs';
import React, { useEffect } from 'react';

import { DownloadLinks } from '@/components/download-links';
import { Heading } from '@/components/heading';
import { PageHeader } from '@/components/page-header';
import { Text } from '@/components/text';

import css from './invitation-redirect.module.scss';

const InvitationRedirect = ({
  DesktopFallbackPage,
  desktopFallbackPageProps = {},
  redirectUrl,
}) => {
  const webUrl = process.env.WEB_BASE_URL;
  const baseUrl = isMobile(window.navigator).phone ? 'amo://m.amo.tm' : webUrl;

  useEffect(() => {
    setTimeout(() => {
      window.location.href = `${baseUrl}${redirectUrl}`;
    }, 100);

    if (isMobile(window.navigator).phone) {
      document.body.classList.add(css.bodyBg);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isMobile(window.navigator).phone) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DesktopFallbackPage {...desktopFallbackPageProps} />;
  }

  return (
    <div className={css.container}>
      <div className={css.mainWrapper}>
        <PageHeader />
        <Heading className={css.downloadHeading} type="download">
          Скачать
        </Heading>
        <Text className={css.downloadText} type="text">
          Скачайте мессенджер amo в Вашем магазине приложений.
        </Text>
        <DownloadLinks className={css.mainDownloadLinks} scale={0.8} />
        <Heading className={css.openHeading} type="open">
          Открыть
        </Heading>
        <Text className={css.openText} type="text">
          Затем откройте мессенджер и начинайте пользоваться всеми функциями.
        </Text>
        <a href={`${baseUrl}${redirectUrl}`} className={css.startButton}>
          Начать
        </a>
      </div>

      <Text className={css.footerText} type="text">
        {`amoCRM @ 2009-${new Date().getFullYear()}. Все права защищены.`}
      </Text>
    </div>
  );
};

export default InvitationRedirect;
