import { useViewportScroll, motion, useTransform } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useDimensions } from "react-hook-dimensions";

import { useBreakpoints } from "@/hooks/use-breakpoints";
import { useSize } from "@/hooks/use-size";
import { useScreenHeight } from "@/hooks/useScreenHeight";

import { BasePage } from "../BasePage";

import { YandexTracker } from "@/trackers/yandex";
import { AdvantagesSection } from "./components/advantages-section";
import { BasementSection } from "./components/basement-section";
import { CommentsSection } from "./components/comments-section";
import { ExplanationSection } from "./components/explanation-section";
import { FirstSection } from "./components/first-section";
import { OpportunitiesSection } from "./components/opportunities-section";
import { VideoSection } from "./components/video-section";
import { withRedirect } from "./withRedirect";

import css from "./IndexPage.module.scss";

const IndexPage = () => {
  const screenHeight = useScreenHeight({
    defaultHeight: 0,
    ignoreResize: true,
  });

  const bps = useBreakpoints();
  const target = useRef(null);
  const size = useSize(target);

  const [firstSectionRef, firstSectionDimensions] = useDimensions({
    dependencies: [size],
  });
  const [explanationSectionRef, explanationSectionDimensions] = useDimensions({
    dependencies: [size],
  });
  const [commentsSectionRef, commentsSectionDimensions] = useDimensions({
    dependencies: [size],
  });
  const [opportunitiesSectionRef, opportunitiesSectionDimensions] =
    useDimensions({
      dependencies: [size],
    });
  const [firstBlockRef, firstBlockDimensions, updateFirstBlockDimensions] =
    useDimensions({
      dependencies: [],
    });
  const [thirdBlockRef, thirdBlockDimensions, updateThirdBlockDimensions] =
    useDimensions({
      dependencies: [],
    });
  const [
    basementBlockRef,
    basementBlockDimensions,
    updateBasementBlockDimensions,
  ] = useDimensions({
    dependencies: [],
  });

  const { scrollY } = useViewportScroll();

  const firstSectionScrollProgressY = useTransform(
    scrollY,
    [
      firstSectionDimensions.positionTop,
      firstSectionDimensions.positionTop + screenHeight,
    ],
    [0, 1]
  );
  const explanationSectionScrollProgressY = useTransform(
    scrollY,
    [
      commentsSectionDimensions.positionTop -
        screenHeight -
        explanationSectionDimensions.height -
        200,
      commentsSectionDimensions.positionTop - screenHeight - 200,
    ],
    [0, 1]
  );
  const opportunitiesPositionTop = opportunitiesSectionDimensions.positionTop;
  const phoneParalaxScrollProgressY = useTransform(
    scrollY,
    [
      opportunitiesPositionTop - screenHeight + 1880,
      opportunitiesPositionTop + 2200,
    ],
    [0, 1]
  );
  const animatedImg1ScrollProgressY = useTransform(
    scrollY,
    [
      opportunitiesPositionTop - screenHeight * 0.67 + 220,
      opportunitiesPositionTop - screenHeight * 0.67 + 600,
    ],
    [0, 1]
  );
  const animatedImg2ScrollProgressY = useTransform(
    scrollY,
    [
      opportunitiesPositionTop -
        screenHeight * 0.67 +
        (bps.isComputedMobileScreen ? 600 : 900),
      opportunitiesPositionTop -
        screenHeight * 0.67 +
        (bps.isComputedMobileScreen ? 1000 : 1200),
    ],
    [0, 1]
  );
  const animatedImg3ScrollProgressY = useTransform(
    scrollY,
    [
      opportunitiesPositionTop -
        screenHeight * 0.72 +
        (bps.isComputedMobileScreen ? 1200 : 1520),
      opportunitiesPositionTop -
        screenHeight * 0.65 +
        (bps.isComputedMobileScreen ? 1600 : 1920),
    ],
    [0, 1]
  );
  const animatedImg4ScrollProgressY = useTransform(
    scrollY,
    [
      opportunitiesPositionTop - screenHeight * 0.67 + 2100,
      opportunitiesPositionTop - screenHeight * 0.5 + 2450,
    ],
    [0, 1]
  );

  const animatedImg5ScrollProgressY = useTransform(
    scrollY,
    [
      opportunitiesPositionTop -
        screenHeight * 0.73 +
        Number(bps.isComputedMobileScreen ? 2000 : 2670),
      opportunitiesPositionTop -
        screenHeight * 0.67 +
        Number(bps.isComputedMobileScreen ? 2350 : 2890),
    ],
    [0, 1]
  );

  const firstBlockHeight = firstBlockDimensions.height;
  const thirdBlockHeight = thirdBlockDimensions.height;
  const basementBlockHeight = basementBlockDimensions.height;
  const thirdBlockProgressY = useTransform(
    scrollY,
    [
      thirdBlockDimensions.positionTop - screenHeight,
      thirdBlockDimensions.positionTop +
        basementBlockHeight -
        screenHeight +
        thirdBlockHeight,
    ],
    [0, 1]
  );
  const thirdBlockBrightness = useTransform(
    thirdBlockProgressY,
    [0, 0.91, 0.91, 1],
    [
      `brightness(${1})`,
      `brightness(${1})`,
      `brightness(${0.2})`,
      `brightness(${0.2})`,
    ]
  );
  const zIndex = useTransform(
    thirdBlockProgressY,
    [0, 0.91, 0.91, 1],
    [2, 2, 6, 6]
  );

  useEffect(() => {
    updateFirstBlockDimensions();
    updateThirdBlockDimensions();
    updateBasementBlockDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (
    <>
      <BasePage wrapperClassName={css.wrapper}>
        <div ref={target}>
          <div
            ref={firstBlockRef}
            className={css.firstBlockFixed}
            style={{
              top: !bps.desktop
                ? screenHeight * 0.8 - firstBlockHeight
                : screenHeight - firstBlockHeight,
            }}
          >
            <FirstSection
              firstSectionRef={firstSectionRef}
              firstSectionScrollProgressY={firstSectionScrollProgressY}
              firstSectionDimensions={firstSectionDimensions}
            />
            <VideoSection />
            <ExplanationSection
              explanationSectionRef={explanationSectionRef}
              explanationSectionScrollProgressY={
                explanationSectionScrollProgressY
              }
              explanationSectionDimensions={explanationSectionDimensions}
            />
          </div>
          <motion.div
            style={{
              filter: bps.mobile && !bps.tablet && thirdBlockBrightness,
              zIndex: bps.mobile && !bps.tablet && zIndex,
            }}
            ref={thirdBlockRef}
            className={css.thirdBlock}
          >
            <CommentsSection />
            <OpportunitiesSection
              opportunitiesSectionRef={opportunitiesSectionRef}
              phoneParalaxScrollProgressY={phoneParalaxScrollProgressY}
              animatedImg1ScrollProgressY={animatedImg1ScrollProgressY}
              animatedImg3ScrollProgressY={animatedImg3ScrollProgressY}
              animatedImg5ScrollProgressY={animatedImg5ScrollProgressY}
              animatedImg2ScrollProgressY={animatedImg2ScrollProgressY}
            />
            <AdvantagesSection />
          </motion.div>
          {bps.isComputed && bps.mobile && !bps.tablet && (
            <div className={css.basementBlockWrapper} ref={basementBlockRef}>
              <BasementSection />
            </div>
          )}
        </div>
      </BasePage>
      <YandexTracker />
    </>
  );
};

export default withRedirect(IndexPage);
