import cx from 'classnames';
import ReactMarkdown from 'react-markdown';

import css from './text.module.scss';

// type Props = {
//   type: 'heading' | 'text';
//   className?: string;
//   children: React.ReactNode;
// };

const Text = ({ className, children, type }) => {
  return (
    <ReactMarkdown className={cx(type === 'heading' ? css.heading : css.text, className)}>
      {children}
    </ReactMarkdown>
  );
};

export default Text;
