// import { useState } from "react";

// import video from '@/videos/promo_video.mp4';
import { Container } from "@/components/container";

import videoPlug from "@/img/video_plug.png";
// import videoMobile from '@/videos/promo_video_mobile.mp4';

// import { useBreakpoints } from '@/hooks/use-breakpoints';

import css from "./video-section.module.scss";

const VideoSection = () => {
  // const bps = useBreakpoints();
  // const [isVideoLoading, setIsVideoLoading] = useState(true);

  return (
    <section className={css.section}>
      <Container className={css.sectionContainer}>
        <div className={css.videoBlock}>
          <div className={css.videoBox}>
            <div className={css.videoContent}>
              <img src={videoPlug} alt="" />
              {/* {isVideoLoading && <img src={videoPlug} alt="" />} */}
              {/* <video
                src={videoMobile}
                loop
                autoPlay
                playsInline
                muted
                style={{ width: '100%', height: '100%' }}
                className={css.video}
                onPlay={() => {
                  setTimeout(() => {
                    setIsVideoLoading(false);
                  }, 50);
                }}
              /> */}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default VideoSection;
