import { motion, useTransform } from "framer-motion";

import { useBreakpoints } from "@/hooks/use-breakpoints";

import { AuthButton } from "@/components/buttons/auth";
import { Container } from "@/components/container";
import { DownloadLinks } from "@/components/download-links";
import { Heading } from "@/components/heading";
import { PageHeader } from "@/components/page-header";

import css from "./first-section.module.scss";

const FirstSection = ({
  firstSectionRef,
  firstSectionScrollProgressY,
  firstSectionDimensions,
}) => {
  const bps = useBreakpoints();

  const onClick = () => {
    // ? here open new window
  };

  const contentOpacity = useTransform(
    firstSectionScrollProgressY,
    [0, 0.5],
    [1, 0]
  );
  const contentY = useTransform(
    firstSectionScrollProgressY,
    [0, 0.5],
    [0, firstSectionDimensions.height / 4]
  );

  return (
    <header ref={firstSectionRef} className={css.section}>
      <Container className={css.sectionContainer}>
        <motion.div
          style={{
            opacity: bps.desktop && contentOpacity,
            translateY: bps.desktop && contentY,
            transform: "translateZ(0px)",
          }}
          className={css.firstSectionMotionDiv}
        >
          <div className={css.firstSectionMotionDiv}>
            <PageHeader
              rightSlot={
                bps.isComputed &&
                bps.desktop && <AuthButton onClick={onClick} />
              }
            />
            <div>
              <Heading className={css.mainHeading} type="main">
                Простая CRM для продаж в мессенджерах
              </Heading>
              {bps.isComputed && (
                <div className={css.main_controls}>
                  {bps.tablet && !bps.desktop && (
                    <AuthButton
                      className={css.start_button}
                      onClick={onClick}
                    />
                  )}
                  {bps.mobile && !bps.tablet && <DownloadLinks scale={0.8} />}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </Container>
    </header>
  );
};

export default FirstSection;
