import cx from 'classnames';
import React from 'react';

import { trackUserAction } from '@/utils/trackUserAction';

import { ReactComponent as DownloadGpIconSprite } from '@/assets/svg/download-gp.svg?sprite';
import { ReactComponent as DownloadIosIconSprite } from '@/assets/svg/download-ios.svg?sprite';
import { ReactComponent as DownloadWebIconSprite } from '@/assets/svg/download-web.svg?sprite';

import css from './download-links.module.scss';

const LINK_TYPES = {
  small: 'small',
  big: 'big',
};

const LINKS_OFFSET = 18;
const LINKS_HEIGHT = 50;
const SMALL_LINKS_WIDTH = 50;
const BIG_LINKS_WIDTH = 170;

const DownloadSingleLink = ({
  type,
  IconSprite,
  downloadLink,
  target,
  ariaLabel,
  scale,
  isFile = false,
  selfTarget = false,
}) => {
  const linksOffset = LINKS_OFFSET * scale;
  const linksHeight = LINKS_HEIGHT * scale;
  const smallLinksWidth = SMALL_LINKS_WIDTH * scale;
  const bigLinksWidth = BIG_LINKS_WIDTH * scale;

  return (
    <a
      target={isFile || selfTarget ? '_self' : '_blank'}
      href={downloadLink}
      rel="nofollow noopener noreferrer"
      className={css.button_link}
      download={isFile}
      onClick={() => {
        if (target) {
          trackUserAction(target);
        }
      }}
      style={{
        marginRight: linksOffset,
      }}
    >
      <IconSprite
        alt={ariaLabel}
        // Если использовать html атрибуты, то сафари их игнорит.
        style={{
          width: type === LINK_TYPES.big ? bigLinksWidth : smallLinksWidth,
          height: linksHeight,
        }}
      />
    </a>
  );
};

const DownloadLinks = ({
  className,
  withWebVersionLink = false,
  scale = 1,
}) => {
  const iosWebLink =
    'https://itunes.apple.com/ru/app/amocrm-2.0/id1128315486?mt=8';
  const androidWebLink =
    'https://play.google.com/store/apps/details?id=com.amocrm.amocrmv2';
  const webLink = `${process.env.REACT_APP_REDIRECT_URL}${window.location.search}`;

  return (
    <div className={cx(css.buttons_row, className)}>
      <DownloadSingleLink
        type={LINK_TYPES.big}
        IconSprite={DownloadIosIconSprite}
        downloadLink={iosWebLink}
        target="click_ios"
        ariaLabel="Скачать из App Store"
        scale={scale}
      />
      <DownloadSingleLink
        type={LINK_TYPES.big}
        IconSprite={DownloadGpIconSprite}
        downloadLink={androidWebLink}
        ariaLabel="Скачать из Google Play"
        target="click_ggl"
        scale={scale}
      />
      {withWebVersionLink && (
        <DownloadSingleLink
          type={LINK_TYPES.small}
          IconSprite={DownloadWebIconSprite}
          downloadLink={webLink}
          ariaLabel="Перейти в Web версию"
          scale={scale}
          selfTarget
        />
      )}
    </div>
  );
};

export default DownloadLinks;
