import { useBreakpoints } from '@/hooks/use-breakpoints';

import { Container } from '@/components/container';
import { Heading } from '@/components/heading';
import { Text } from '@/components/text';

import css from './advantages-section.module.scss';

const AdvantagesBlock = ({ heading, text, className, content }) => {
  return (
    <div className={className}>
      <Heading type="advantages">
        <Text type="heading">{heading}</Text>
      </Heading>
      {text ? (
        <Text className={css.advantagesText} type="text">
          {text}
        </Text>
      ) : null}
      {content ? content : null}
    </div>
  );
};
const AdvantagesSection = () => {
  const bps = useBreakpoints();
  return (
    <section className={css.section}>
      <Container>
        <div className={css.advantagesSection}>
          <AdvantagesBlock
            heading={`Удобный интерфейс`}
            text="Простой и понятный интерфейс, где нет ничего лишнего. Все, что нужно для успешной работы - всегда будет под рукой."
            className={css.advantagesBlock}
          />
          <AdvantagesBlock
            heading="Один аккаунт для всех"
            text="Больше не нужно думать о передаче своих паролей для мессенджеров и соц. сетей. Все аккаунты доступны из одного удобного интерфейса, поэтому ваши менеджеры могут уделить максимум внимания клиентам, не переживая о паролях и доступах."
            className={css.advantagesBlock}
          />
          <AdvantagesBlock
            heading="Цена"
            text="amoChats стоит всего 999 рублей в месяц за 5 пользователей, а первый месяц доступен бесплатно."
            className={css.advantagesBlock}
          />
        </div>
        {bps.tablet && <div className={css.emptyEl}></div>}
      </Container>
    </section>
  );
};

export default AdvantagesSection;
