import cx from "classnames";
import { motion, useTransform } from "framer-motion";

import message1 from "@/img/amoChatsPreview/mailing/block_1.png";
import message2 from "@/img/amoChatsPreview/mailing/block_2.png";
import message3 from "@/img/amoChatsPreview/mailing/block_3.png";
import message5 from "@/img/amoChatsPreview/mailing/block_6.png";
import message4_real from "@/img/amoChatsPreview/mailing/block_4.png";
import message7 from "@/img/amoChatsPreview/mailing/block_7.png";
import imageSrc1 from "@/img/amoChatsPreview/mailing/background.png";

import css from "../opportunities-section.module.scss";

export const AnimatedImage2 = ({ className, scrollProgressY }) => {
  const step = 0.2;
  const firstStart = 0.003;
  const firstEnd = 0.103;

  const opasityMsg1 = useTransform(
    scrollProgressY,
    [0, 0, firstStart + 4 * step, firstEnd + 4 * step],
    [1, 1, 1, 0]
  );
  const opasityMsg2 = useTransform(
    scrollProgressY,
    [
      firstStart + step,
      firstStart + step + 0.001,
      firstStart + 4 * step,
      firstEnd + 4 * step,
    ],
    [0, 1, 1, 0]
  );
  const opasityMsg3 = useTransform(
    scrollProgressY,
    [
      firstStart + 1.5 * step,
      firstStart + 1.5 * step + 0.0001,
      firstStart + 4 * step,
      firstEnd + 4 * step,
    ],
    [0, 1, 1, 0]
  );
  const translateMsg4 = useTransform(
    scrollProgressY,
    [firstStart + 2.2 * step + 0.0001, firstEnd + 2.2 * step + 0.1, 1],
    [10, 0, 0]
  );
  const opasityMsg4 = useTransform(
    scrollProgressY,
    [
      firstStart + 2.2 * step,
      firstStart + 2.2 * step + 0.001,
      firstEnd + 2.2 * step + 0.001,
      firstStart + 4 * step,
      firstEnd + 4 * step,
    ],
    [0, 0.2, 1, 1, 0]
  );
  const opasityMsg5 = useTransform(
    scrollProgressY,
    [firstStart + 3.7 * step, firstStart + 4 * step, firstEnd + 4 * step],
    [0, 1, 1]
  );

  return (
    <div className={cx(className, css.animatedImgBlock)}>
      <img src={imageSrc1} alt="" className={css.animatedImg} />
      <motion.img
        src={message1}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg1 }}
      />
      <motion.img
        src={message2}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg2 }}
      />
      <motion.img
        src={message3}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg3 }}
      />
      <motion.img
        src={message4_real}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg4 }}
      />
      <motion.img
        src={message7}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg4, translateY: translateMsg4 }}
      />
      <motion.img
        src={message5}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg5 }}
      />
    </div>
  );
};
