/* eslint-disable react/button-has-type */
import cx from "classnames";
import React from "react";

import { useBreakpoints } from "@/hooks/use-breakpoints";

import css from "./auth-button.module.scss";

// type Props = {
//   children?: string;
//   className?: string;
//   onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
//   disabled?: boolean;
//   type?: 'button' | 'submit' | 'reset';
//   invert?: boolean;
//   userName?: string;
//   state?: 'empty' | 'pending' | 'done' | 'error';
// };

const AuthButton = ({
  children = "Начать",
  onClick,
  disabled,
  type = "button",
  invert,
  className,
}) => {
  const bps = useBreakpoints();

  if (!bps.isComputed) {
    return null;
  }

  return (
    <a
      target="_self"
      href={`${process.env.REACT_APP_REDIRECT_URL}${window.location.search}`}
    >
      <button
        className={cx(
          css.authButton,
          { [css.disabled]: disabled, [css.invert]: invert },
          className
        )}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </a>
  );
};

export default AuthButton;
