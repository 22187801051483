import { memo, Suspense, useCallback, useEffect, useMemo, useReducer } from 'react';

import { withErrorBoundary } from '@/hocs/withErrorBoundary';

import { logError } from '@/utils/errors';

import { closeModal } from './actions';
import { ACTIONS_EVENT_NAMES, MAIN_PROVIDER_KEY } from './constatns';
import { emitter } from './emitter';
import { makeEventKey } from './helpers';

const Modal = memo(({ ModalComponent, modalParams }) => {
  const closeCurrentModal = useCallback(() => {
    closeModal({
      id: modalParams.id,
    });
  }, [modalParams.id]);

  const onError = useCallback(
    (error, errorInfo) => {
      if (typeof modalParams.onError === 'function') {
        modalParams.onError({ error, errorInfo, closeModal: closeCurrentModal });
        return;
      }

      logError(error, errorInfo);
      closeCurrentModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeCurrentModal, modalParams.onError]
  );

  const SuspenseFallbackComponent = useMemo(() => {
    if (typeof modalParams.SuspenseFallbackComponent === 'function') {
      return modalParams.SuspenseFallbackComponent;
    }

    return () => {
      return null;
    };
  }, [modalParams.SuspenseFallbackComponent]);

  const ModalComponentWithErrorBoundary = useMemo(() => {
    return withErrorBoundary(ModalComponent, {
      onError,
      ...(typeof modalParams.ErrorFallbackComponent === 'function'
        ? modalParams.ErrorFallbackComponent
        : {}),
    });
  }, [ModalComponent, modalParams.ErrorFallbackComponent, onError]);

  useEffect(() => {
    if (typeof modalParams.onOpen === 'function') {
      modalParams.onOpen();
    }

    return () => {
      if (typeof modalParams.onClose === 'function') {
        modalParams.onClose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<SuspenseFallbackComponent />}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ModalComponentWithErrorBoundary closeModal={closeCurrentModal} {...modalParams.props} />;
    </Suspense>
  );
});

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS_EVENT_NAMES.open: {
      const { modalParams } = action.payload;
      const { id } = modalParams;

      if (!id || id in state.entities) {
        return state;
      }

      return {
        ids: state.ids.concat(id),
        entities: {
          ...state.entities,
          [id]: modalParams,
        },
      };
    }
    case ACTIONS_EVENT_NAMES.close: {
      const { modalParams } = action.payload;
      const { id, modalName } = modalParams;

      if (!id && !modalName) {
        return state;
      }

      const clonedEntities = { ...state.entities };

      if (id) {
        if (id in clonedEntities) {
          const updatedIds = state.ids.filter((existingId) => {
            return existingId !== id;
          });
          delete clonedEntities[id];

          return {
            ids: updatedIds,
            entities: clonedEntities,
          };
        }
        return state;
      }

      // TODO: Search latest by name
      return state;

      // return {
      //   ids: state.ids.concat(id),
      //   entities: {
      //     ...state.entities,
      //     [id]: modalParams,
      //   },
      // };
    }
    case ACTIONS_EVENT_NAMES.closeAll: {
      return {
        ids: [],
        entities: {},
      };
    }
    default:
      throw new Error('Unknown type');
  }
}

export const ModalsProvider = ({ providerKey = MAIN_PROVIDER_KEY, modals = {} }) => {
  const [state, dispatch] = useReducer(reducer, {
    ids: [],
    entities: {},
  });

  const { openModalEventKey, closeModalEventKey, closeAllModalsEventKey } = useMemo(() => {
    return {
      openModalEventKey: makeEventKey(providerKey, ACTIONS_EVENT_NAMES.open),
      closeModalEventKey: makeEventKey(providerKey, ACTIONS_EVENT_NAMES.close),
      closeAllModalsEventKey: makeEventKey(providerKey, ACTIONS_EVENT_NAMES.closeAll),
    };
  }, [providerKey]);

  useEffect(() => {
    function onOpenModal(openModalParams) {
      dispatch({
        type: ACTIONS_EVENT_NAMES.open,
        payload: {
          modalParams: openModalParams,
        },
      });
    }

    emitter.on(openModalEventKey, onOpenModal);

    return () => {
      emitter.removeListener(openModalEventKey, onOpenModal);
    };
  }, [openModalEventKey]);

  useEffect(() => {
    function onCloseModal(closeModalParams) {
      dispatch({
        type: ACTIONS_EVENT_NAMES.close,
        payload: {
          modalParams: closeModalParams,
        },
      });
    }

    emitter.on(closeModalEventKey, onCloseModal);

    return () => {
      emitter.removeListener(closeModalEventKey, onCloseModal);
    };
  }, [closeModalEventKey]);

  useEffect(() => {
    function onCloseAllModals() {
      dispatch({
        type: ACTIONS_EVENT_NAMES.closeAll,
      });
    }

    emitter.on(closeAllModalsEventKey, onCloseAllModals);

    return () => {
      emitter.removeListener(closeAllModalsEventKey, onCloseAllModals);
    };
  }, [closeAllModalsEventKey]);

  return state.ids.map((modalId) => {
    const currentModalParams = state.entities[modalId];
    const { modalName, id } = currentModalParams;

    const typeModalParams = modals[modalName];
    const { component: ModalComponent } = typeModalParams;

    return (
      <Modal
        // eslint-disable-next-line react/no-array-index-key
        key={id}
        ModalComponent={ModalComponent}
        modalParams={currentModalParams}
      />
    );
  });
};
