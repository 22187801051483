import cx from 'classnames';

import css from './heading.module.scss';

// type Props = {
//   className?: string;
//   type:
//     | 'main'
//     | 'explanation-section'
//     | 'comments-section'
//     | 'opportunities'
//     | 'advantages'
//     | 'basement'
//     | 'download'
//     | 'open'
//     | 'secondary';
//   children: React.ReactNode;
// };

const Heading = ({ className, type, children }) => {
  switch (type) {
    case 'main': {
      return <h1 className={cx(css.heading, css.main, className)}>{children}</h1>;
    }
    case 'explanation-section': {
      return <h2 className={cx(css.heading, css.explanationSection, className)}>{children}</h2>;
    }
    case 'comments-section': {
      return <h2 className={cx(css.heading, css.commentsSection, className)}>{children}</h2>;
    }
    case 'opportunities': {
      return <h3 className={cx(css.heading, css.opportunities, className)}>{children}</h3>;
    }
    case 'advantages': {
      return <h3 className={cx(css.heading, css.advantages, className)}>{children}</h3>;
    }
    case 'basement': {
      return <h2 className={cx(css.heading, css.basement, className)}>{children}</h2>;
    }
    case 'download': {
      return <h3 className={cx(css.heading, css.download, className)}>{children}</h3>;
    }
    case 'open': {
      return <h3 className={cx(css.heading, css.open, className)}>{children}</h3>;
    }
    case 'secondary': {
      return <h2 className={cx(css.heading, css.secondary, className)}>{children}</h2>;
    }
    default:
      return null;
  }
};

export default Heading;
