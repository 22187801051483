import cx from "classnames";
import { motion, useTransform } from "framer-motion";

import { useBreakpoints } from "@/hooks/use-breakpoints";

import { Container } from "@/components/container";
import { Heading } from "@/components/heading";
import { Text } from "@/components/text";

import imageSrc1 from "@/img/amoChatsPreview/1.JPG";
import imageSrc2 from "@/img/amoChatsPreview/2.JPG";
import imageSrc3 from "@/img/amoChatsPreview/3.JPG";
import imageSrc4 from "@/img/amoChatsPreview/4.JPG";
import imageSrc5 from "@/img/amoChatsPreview/5.png";
import phone from "@/img/amoChatsPreview/phone.PNG";
import poster from "@/img/poster.png";

import css from "./opportunities-section.module.scss";
import { AnimatedImage1 } from "./components/animated-image1";
import { AnimatedImage3 } from "./components/animated-image3";
import { AnimatedImage5 } from "./components/animated-image5";
import { AnimatedImage2 } from "./components/animated-image2";

const OpportunityBlock = ({
  heading,
  text,
  image,
  video,
  className,
  highImg,
  animated,
  animatedImage,
}) => {
  return (
    <div className={cx(className)}>
      <div className={css.opportunityTextPart}>
        <Heading className={css.opportunityHeading} type="opportunities">
          {heading}
        </Heading>
        <Text className={css.opportunityText} type="text">
          {text}
        </Text>
      </div>
      <div
        className={cx(
          css.opportunityImgBlock,
          highImg && css.opportunityImgHighBlock
        )}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          animated ? (
            animatedImage
          ) : video ? (
            <video
              src={video}
              poster={poster}
              loop
              autoPlay
              playsInline
              muted
              className={css.opportunityVideo}
            />
          ) : (
            <img src={image} alt="" className={css.opportunityImg} />
          )
        }
      </div>
    </div>
  );
};

const OpportunitiesSection = ({
  opportunitiesSectionRef,
  phoneParalaxScrollProgressY,
  animatedImg1ScrollProgressY,
  animatedImg2ScrollProgressY,
  animatedImg3ScrollProgressY,
  animatedImg5ScrollProgressY,
}) => {
  const bps = useBreakpoints();
  const translatePhoneImg = useTransform(
    phoneParalaxScrollProgressY,
    [0, 1],
    [1200, 770]
  );

  return (
    <section className={css.section} ref={opportunitiesSectionRef}>
      <motion.div>
        <Container>
          <OpportunityBlock
            heading="imBox"
            text={`Уникальное решение, которое собирает все ваши диалоги с клиентами из любых мессенджеров в одном месте. Независимо от того, используете ли вы WhatsApp, Telegram, Viber или другие популярные мессенджеры - все сообщения будут доступны из imBox.`}
            image={imageSrc3}
            className={css.opportunityBlock}
            animated
            animatedImage={
              <AnimatedImage1
                scrollProgressY={animatedImg1ScrollProgressY}
                className={css.opportunityImg}
              />
            }
          />
          <OpportunityBlock
            heading="Рассылки"
            text={`Экономьте время и ресурсы с помощью нашего сервиса рассылок. Не важно, как вы общаетесь со своими клиентами: через Telegram, WhatsApp или даже социальные сети - вы всегда сможете напомнить о себе всего одним нажатием кнопки. Используйте наши готовые шаблоны, чтобы ещё больше упростить процесс.`}
            className={css.opportunityBlock}
            image={imageSrc4}
            animated
            animatedImage={
              <AnimatedImage2
                scrollProgressY={animatedImg2ScrollProgressY}
                className={css.opportunityImg}
              />
            }
          />
          <OpportunityBlock
            heading="Внутренние сообщения"
            text={`Прямо в карточке клиента вы можете обсудить вопрос клиента между собой. Больше никаких лишних переключений между приложениями - упрощайте коммуникацию с командой и решайте вопросы клиентов быстрее.`}
            className={css.opportunityBlock}
            animated
            animatedImage={
              <AnimatedImage3
                scrollProgressY={animatedImg3ScrollProgressY}
                className={css.opportunityImg}
              />
            }
            image={imageSrc1}
          />
          {bps.desktop && (
            <motion.div
              className={css.phoneImg}
              style={{ translateY: translatePhoneImg }}
            >
              <img src={phone} alt="" />
            </motion.div>
          )}
          <OpportunityBlock
            heading="Официальный WhatsApp"
            text={`Используйте все преимущества официального WhatsApp. Быстрые ответы, возможность начать диалог первым и никакого риска блокировок - пусть продажи станут ещё эффективнее.`}
            className={css.opportunityBlock}
            image={imageSrc2}
          />
          <OpportunityBlock
            heading="Онлайн-чат"
            text={`Разместите кнопку со всеми источниками связи на вашем сайте. Клиент сможет связаться с вами без проблем по удобному для него каналу. А если вдруг у него нет нужного мессенджера, он всегда сможет написать вам из кнопки через форму онлайн-чата.`}
            className={css.opportunityBlock}
            animated
            animatedImage={
              <AnimatedImage5
                scrollProgressY={animatedImg5ScrollProgressY}
                className={css.opportunityImg}
              />
            }
            image={imageSrc5}
          />
        </Container>
      </motion.div>
    </section>
  );
};

export default OpportunitiesSection;
