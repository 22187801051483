import { BreakpointsProvider } from "./hooks/use-breakpoints";
import { Router } from "./services/modalsProvider/router";
import "@/styles/main.scss";

function App() {
  return (
    <BreakpointsProvider>
      <Router />
    </BreakpointsProvider>
  );
}

export default App;
