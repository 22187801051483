import { isDevelopment, isServer } from '@/utils/helpers';

import { logFacebookAnalytic } from './analytics/facebook';
import { logGoogleAnalytic } from './analytics/google';
import { logYandexAnalytic } from './analytics/yandex';

export const trackUserAction = (target) => {
  if (isServer || isDevelopment) {
    return;
  }

  logYandexAnalytic(target);
  logFacebookAnalytic(target);
  logGoogleAnalytic(target);
};
