import useResizeObserver from '@react-hook/resize-observer';
import { useState } from 'react';

import { useIsomorphicLayoutEffect } from '@/hooks/use-isomorphic-layout-effect';

export const useSize = (target) => {
  const [size, setSize] = useState();

  useIsomorphicLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => {
    setSize(entry.contentRect);
  });
  return size;
};
