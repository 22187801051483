import cx from 'classnames';
import React from 'react';

import css from './container.module.scss';

const Container = ({ children, className, style }) => {
  return (
    <div className={cx(className, css.container)} style={style}>
      {children}
    </div>
  );
};

export default Container;
