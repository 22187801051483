import { isServer, isDevelopment } from '@/utils/helpers';

export const logGoogleAnalytic = (targetName) => {
  if (isServer || isDevelopment) {
    return;
  }

  if (window.dataLayer) {
    window.gtag('event', targetName);
  }
};
