import cx from "classnames";
import { motion, useTransform } from "framer-motion";

import { useBreakpoints } from "@/hooks/use-breakpoints";

import { Container } from "@/components/container";
import { Heading } from "@/components/heading";
import { Text } from "@/components/text";

import css from "./explanation-section.module.scss";

const ExplanationSection = ({
  explanationSectionRef,
  explanationSectionScrollProgressY,
}) => {
  const bps = useBreakpoints();
  const contentScale = useTransform(
    explanationSectionScrollProgressY,
    [0.4, 0.95],
    [0.8, 1]
  );
  const opacity = useTransform(
    explanationSectionScrollProgressY,
    [0.4, 0.95],
    [0, 1]
  );

  return (
    <section ref={explanationSectionRef} className={css.section}>
      <Container className={css.sectionContainer}>
        <motion.div
          style={{
            opacity: bps.desktop && opacity,
            scale: bps.desktop && contentScale,
            transform: "translateZ(0px)",
          }}
          className={css.explanationSectionMotionDiv}
        >
          <div className={css.explanationSectionMotionDiv}>
            <Heading
              className={css.explanationHeading}
              type="explanation-section"
            >
              Все клиенты вместе
            </Heading>
            <Text
              className={cx(css.explanationText, {
                [css.explanationText_Small]: bps.isTabletToDesktop,
              })}
              type="text"
            >
              {`Больше не нужно переключаться между разными окнами мессенджеров и социальных сетей - все обращения от клиентов доступны в одном окне.\nВы даже можете написать им всем сразу.`}
            </Text>
          </div>
        </motion.div>
      </Container>
    </section>
  );
};

export default ExplanationSection;
