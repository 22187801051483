/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cx from "classnames";
import { Link } from "react-router-dom";

import { ReactComponent as AmoLogoInvert } from "@/svg/logo-full-white.svg?sprite";
import { ReactComponent as AmoLogo } from "@/svg/logo-full.svg?sprite";

import css from "./page-header.module.scss";

const PageHeader = ({
  rightSlot = null,
  invert = false,
  className = "",
  logoLinkUrl = false,
}) => {
  const logoComponent = invert ? <AmoLogoInvert /> : <AmoLogo />;

  return (
    <div className={cx(className, css.header)}>
      <div className={css.page_title}>
        {logoLinkUrl ? (
          <Link href="/">
            <a className={css.page_title_link}>{logoComponent}</a>
          </Link>
        ) : (
          logoComponent
        )}
      </div>
      {rightSlot && <div className={css.right_slot}>{rightSlot}</div>}
    </div>
  );
};

export default PageHeader;
