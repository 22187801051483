import { Container } from '@/components/container';
import { DownloadLinks } from '@/components/download-links';
import { Heading } from '@/components/heading';
import { PageHeader } from '@/components/page-header';

import css from './basement-section.module.scss';

const currentYear = new Date().getFullYear();

const BasementSection = () => {
  return (
    <section className={css.section}>
      <Container>
        <div className={css.basementSection}>
          <PageHeader />
          <div>
            <Heading className={css.basementHeading} type="basement">
              Скачать
            </Heading>
            <DownloadLinks className={css.basementDownloadLinks} scale={0.8} />
          </div>
          <footer className={css.footer}>
            <div>amoCRM @ 2009-{currentYear}. Все права защищены.</div>
          </footer>
        </div>
      </Container>
    </section>
  );
};

export default BasementSection;
