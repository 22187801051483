import cx from "classnames";
import React, { useEffect } from "react";

import { useBreakpoints } from '@/hooks/use-breakpoints';
import { useChangeEffect } from "@/hooks/useChangeEffect";
import {
  ModalsProvider,
  openModal,
} from "@/services/modalsProvider";

import { usePageBottom } from '@/hooks/usePageBottom';

import { MODAL_NAMES } from "@/utils/constants";
import { clearAllScrollLocks } from "@/utils/scroll-locker";

import css from "./BasePage.module.scss";

const DocumentModal = React.lazy(() => {
  return import("@/components/modals/DocumentModal");
});
const StartModal = React.lazy(() => {
  return import('@/components/modals/StartModal');
});

const modals = {
  [MODAL_NAMES.document]: {
    component: DocumentModal,
  },
  [MODAL_NAMES.start]: {
    component: StartModal,
  },
};

const BasePage = ({ children, wrapperClassName, customMenuModalProps }) => {
  const bps = useBreakpoints();
  const isBottomReached = usePageBottom();

  useChangeEffect(() => {
    if (bps.tablet && isBottomReached) {
      openModal({
        modalName: MODAL_NAMES.start,
      });
    }
  }, [isBottomReached]);

  useEffect(() => {
    return () => {
      clearAllScrollLocks();
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className={cx(wrapperClassName)}
      >
        {children}
      </div>
      <ModalsProvider modals={modals} />
    </React.Fragment>
  );
};

export default BasePage;
