import cx from "classnames";
import { motion } from "framer-motion";

import { Container } from "@/components/container";
import { Heading } from "@/components/heading";
import { Text } from "@/components/text";

import css from "./comments-section.module.scss";

const Comment = ({ text, authorName, authorInfo, authorImg, className }) => {
  return (
    <div className={cx(className)}>
      <Text type="text">{text}</Text>
    </div>
  );
};

const CommentsSection = ({ className, commentsSectionRef }) => {
  return (
    <section className={cx(css.section, className)} ref={commentsSectionRef}>
      <motion.div>
        <Container>
          <motion.div>
            <Heading className={css.commentsHeading} type="comments-section">
              Самая простая CRM
            </Heading>
            <div className={css.commentsBlock}>
              <Comment
                text={`Считаете, что внедрить CRM-систему слишком сложная задача для маленького бизнеса? В таком случае **amoChats** может стать идеальным решением для вас, с помощью которого вы сможете вести базу покупателей, общаться с клиентами в разных мессенджерах в режиме одного окна. 

Настройка системы и подключение первых источников с подробным онбординг-туром займёт у вас всего несколько минут. Также для amoChats доступна нативная интеграция с самым популярным для продаж мессенджером - Whatsapp`}
                className={css.comment}
              />
            </div>
          </motion.div>
        </Container>
      </motion.div>
    </section>
  );
};

export default CommentsSection;
