import cx from "classnames";
import { motion, useTransform } from "framer-motion";

import message1 from "@/img/amoChatsPreview/imbox/block_1.png";
import message2 from "@/img/amoChatsPreview/imbox/block_2.png";
import message3 from "@/img/amoChatsPreview/imbox/block_3.png";
import message4 from "@/img/amoChatsPreview/imbox/block_4.png";
import message5 from "@/img/amoChatsPreview/imbox/block_5.png";
import imageSrc1 from "@/img/amoChatsPreview/imbox/background.png";

import css from "../opportunities-section.module.scss";

export const AnimatedImage1 = ({ className, scrollProgressY }) => {
  const step = 0.2;
  const firstStart = 0;
  const firstEnd = 0.1;
  const opasityMsg1 = useTransform(
    scrollProgressY,
    [firstStart, firstStart + 0.001, 1],
    [0, 1, 1]
  );
  const translateMsg1 = useTransform(
    scrollProgressY,
    [firstStart, firstEnd, 1],
    [0, 0, 0]
  );
  const opasityMsg2 = useTransform(
    scrollProgressY,
    [firstStart + step, firstStart + step + 0.001, 1],
    [0, 1, 1]
  );
  const translateMsg2 = useTransform(
    scrollProgressY,
    [firstStart + step, firstEnd + step, 1],
    [0, 0, 0]
  );
  const opasityMsg3 = useTransform(
    scrollProgressY,
    [firstStart + 2 * step, firstStart + 2 * step + 0.001, 1],
    [0, 1, 1]
  );
  const translateMsg3 = useTransform(
    scrollProgressY,
    [firstStart + 2 * step, firstEnd + 2 * step, 1],
    [0, 0, 0]
  );
  const opasityMsg4 = useTransform(
    scrollProgressY,
    [firstStart + 3 * step, firstStart + 3 * step + 0.001, 1],
    [0, 1, 1]
  );
  const translateMsg4 = useTransform(
    scrollProgressY,
    [firstStart + 3 * step, firstEnd + 3 * step, 1],
    [0, 0, 0]
  );
  const opasityMsg5 = useTransform(
    scrollProgressY,
    [firstStart + 4 * step, firstStart + 4 * step + 0.001, 1],
    [0, 1, 1]
  );
  const translateMsg5 = useTransform(
    scrollProgressY,
    [firstStart + 4 * step, firstEnd + 4 * step, 1],
    [0, 0, 0]
  );

  return (
    <div className={cx(className, css.animatedImgBlock)}>
      <img src={imageSrc1} alt="" className={css.animatedImg} />
      <motion.img
        src={message1}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg1, translateY: translateMsg1 }}
      />
      <motion.img
        src={message2}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg2, translateY: translateMsg2 }}
      />
      <motion.img
        src={message3}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg3, translateY: translateMsg3 }}
      />
      <motion.img
        src={message4}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg4, translateY: translateMsg4 }}
      />
      <motion.img
        src={message5}
        alt=""
        className={css.message}
        style={{ opacity: opasityMsg5, translateY: translateMsg5 }}
      />
    </div>
  );
};
