export const AMO_API_URL = `${process.env.BASE_URL}/v1`;

export const MODAL_NAMES = {
  document: 'document',
  start: 'start',
};

export const DOCUMENT_MODAL_TYPES = {
  agreement: 'agreement',
  security: 'security',
  confidence: 'confidence',
};

export const JS_CLASS_NAMES = {
  withPreventScrollOffset: 'js-scroll-preventable',
  withPreventScrollTouch: 'js-scroll-touch',
};
