import React from "react";
import { useSearchParams } from "react-router-dom";

import { map, pick } from "lodash";

import { InvitationRedirect } from "./components/invitation-redirect";

const obj2qs = (obj) => {
  return map(obj, (v, k) => {
    return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
  }).join("&");
};

export const withRedirect = (Page) => {
  return (props) => {
    const [queryParams] = useSearchParams();
    let redirectUrl = "";

    if (typeof window !== "undefined") {
      // handle login redirect
      // TODO: Перенести это в script, чтобы редирект делался до рендера реакта
      if (queryParams.get("action") === "login" && queryParams.get("login")) {
        redirectUrl = `/login?${obj2qs(
          pick(queryParams.getAll(), ["login", "s"])
        )}`;
      } else if (
        queryParams.get("action") === "activate" &&
        (queryParams.get("login") || queryParams.get("s"))
      ) {
        redirectUrl = `/activate?${obj2qs(
          pick(queryParams.getAll(), ["login", "s"])
        )}`;
      }

      if (redirectUrl) {
        return (
          <InvitationRedirect
            DesktopFallbackPage={Page}
            desktopFallbackPageProps={props}
            redirectUrl={redirectUrl}
          />
        );
      }
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Page {...props} />;
  };
};
