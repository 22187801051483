import { useState } from 'react';

import { debounce } from 'lodash';

import { useIsomorphicLayoutEffect } from '@/hooks/use-isomorphic-layout-effect';

function useScreenHeight({ ignoreResize = false, defaultHeight = '100vh' } = {}) {
  const [screenHeight, setScreenHeight] = useState(defaultHeight);

  const correctSizing = debounce(() => {
    setScreenHeight((currentScreenHeight) => {
      let newScreenHeight = window.innerHeight;
      if (newScreenHeight > window.screen.height) {
        newScreenHeight /= window.devicePixelRatio;
      }

      if (currentScreenHeight === '100vh') {
        return newScreenHeight;
      }

      return Math.max(currentScreenHeight, newScreenHeight);
    });
  }, 50);

  const resetScreenHeight = () => {
    setScreenHeight(defaultHeight);
  };

  useIsomorphicLayoutEffect(() => {
    correctSizing();

    if (ignoreResize) {
      const onOrientationchange = () => {
        window.addEventListener('resize', correctSizing, { once: true });
      };

      window.addEventListener('orientationchange', onOrientationchange);

      return () => {
        window.removeEventListener('orientationchange', onOrientationchange);
      };
    }

    window.addEventListener('orientationchange', resetScreenHeight);
    window.addEventListener('resize', correctSizing);

    return () => {
      window.removeEventListener('resize', correctSizing);
      window.removeEventListener('orientationchange', resetScreenHeight);
    };
  }, []);

  return screenHeight;
}

export default useScreenHeight;
