import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { JS_CLASS_NAMES } from '@/utils/constants';
import { createTrackedValue, isServer, noop, once } from '@/utils/helpers';

const makeScrollLocker = ({
  onScrollLocksAdd,
  onScrollLocksRemove,
  onBodyScrollLockAdd,
  onBodyScrollLockRemove,
} = {}) => {
  if (isServer) {
    return {
      addScrollLocks: noop,
      removeScrollLocks: noop,
    };
  }

  const locksSet = new Set();

  const { setValue: setIsLocked } = createTrackedValue({
    onChange: (newIsLocked) => {
      if (newIsLocked && typeof onBodyScrollLockAdd === 'function') {
        onBodyScrollLockAdd();
      }

      if (!newIsLocked && typeof onBodyScrollLockRemove === 'function') {
        onBodyScrollLockRemove();
      }
    },
    defaultValue: false,
  });

  const addScrollLocks = (els, { allowTouchMove = undefined } = {}) => {
    const newScrollLocks = els.filter((el) => {
      return el && !locksSet.has(el);
    });

    if (!newScrollLocks.length) {
      return;
    }

    newScrollLocks.forEach((newScrollLock) => {
      locksSet.add(newScrollLock);
      disableBodyScroll(
        newScrollLock,
        allowTouchMove
          ? {
              allowTouchMove,
            }
          : {}
      );
    });

    setIsLocked(Boolean(locksSet.size));

    if (typeof onScrollLocksAdd === 'function') {
      onScrollLocksAdd(newScrollLocks);
    }
  };

  const removeScrollLocks = (els) => {
    const existingScrollLocks = els.filter((el) => {
      return el && locksSet.has(el);
    });

    if (!existingScrollLocks.length) {
      return;
    }

    existingScrollLocks.forEach((existingScrollLock) => {
      locksSet.delete(existingScrollLock);
      enableBodyScroll(existingScrollLock);
    });

    setIsLocked(Boolean(locksSet.size));

    if (typeof onScrollLocksRemove === 'function') {
      onScrollLocksRemove(existingScrollLocks);
    }
  };

  const clearAllScrollLocks = () => {
    locksSet.clear();
    setIsLocked(Boolean(locksSet.size));

    clearAllBodyScrollLocks();
  };

  return {
    addScrollLocks,
    removeScrollLocks,
    clearAllScrollLocks,
  };
};

const getScrollBarWidth = once(function computeScrollBarWidth(class_name) {
  const outer = document.createElement('div');

  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;

  // force scrollbars
  outer.style.overflow = 'scroll';
  outer.className = class_name || '';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
});

const getDefaultScrollLockerOptions = ({
  fixedElsSelector = '',
  reservedGapOriginalStyleOffsetAttribute = 'data-reserved-gap-original-style-offset',
  reservedGapMarkAttribute = 'data-reserved-gap',
  reservedProperty = 'padding-right',
} = {}) => {
  if (isServer) {
    return {};
  }

  let isBodyScrollLocked = false;

  const addReservedGap = (el) => {
    if (!el || typeof el.hasAttribute !== 'function' || el.hasAttribute(reservedGapMarkAttribute)) {
      return;
    }

    const originalStyleOffset = parseFloat(el.style[reservedProperty]);

    if (!Number.isNaN(originalStyleOffset)) {
      const newOffset = originalStyleOffset + getScrollBarWidth();

      el.setAttribute(reservedGapMarkAttribute, true);
      el.setAttribute(reservedGapOriginalStyleOffsetAttribute, originalStyleOffset);
      el.style.setProperty(reservedProperty, `${newOffset}px`);
      return;
    }

    const originalComputedStyleOffset = parseFloat(getComputedStyle(el)[reservedProperty]) || 0;

    const newOffset = originalComputedStyleOffset + getScrollBarWidth();
    el.setAttribute(reservedGapMarkAttribute, true);
    el.style.setProperty(reservedProperty, `${newOffset}px`);
  };

  const removeReservedGap = (el) => {
    if (
      !el ||
      typeof el.hasAttribute !== 'function' ||
      !el.hasAttribute(reservedGapMarkAttribute)
    ) {
      return;
    }

    el.removeAttribute(reservedGapMarkAttribute);

    if (el.hasAttribute(reservedGapOriginalStyleOffsetAttribute)) {
      const reservedGapOriginalStyleOffset = parseFloat(
        el.getAttribute(reservedGapOriginalStyleOffsetAttribute)
      );
      el.style.setProperty(reservedProperty, `${reservedGapOriginalStyleOffset}px`);
      el.removeAttribute(reservedGapOriginalStyleOffsetAttribute);
      return;
    }

    el.style.removeProperty(reservedProperty);
  };

  const updateFixedElsReservedGap = () => {
    if (!fixedElsSelector) {
      return;
    }

    const fixedEls = document.querySelectorAll(fixedElsSelector);

    fixedEls.forEach((fixedEl) => {
      if (isBodyScrollLocked) {
        addReservedGap(fixedEl);
        return;
      }
      removeReservedGap(fixedEl);
    });
  };

  const onBodyScrollLockAdd = () => {
    isBodyScrollLocked = true;

    addReservedGap(document.body);
  };

  const onScrollLocksAdd = (els) => {
    els.forEach((el) => {
      addReservedGap(el);
    });
    updateFixedElsReservedGap();
  };

  const onBodyScrollLockRemove = () => {
    isBodyScrollLocked = false;

    removeReservedGap(document.body);
  };

  const onScrollLocksRemove = (els) => {
    els.forEach((el) => {
      removeReservedGap(el);
    });
    updateFixedElsReservedGap();
  };

  return {
    onBodyScrollLockAdd,
    onScrollLocksAdd,
    onBodyScrollLockRemove,
    onScrollLocksRemove,
  };
};

export const { addScrollLocks, removeScrollLocks, clearAllScrollLocks } = makeScrollLocker(
  getDefaultScrollLockerOptions({
    fixedElsSelector: `.${JS_CLASS_NAMES.withPreventScrollOffset}`,
  })
);
