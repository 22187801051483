import React from 'react';

export const withErrorBoundary = (
  Component,
  {
    FallbackComponent = () => {
      return null;
    },
    onError,
  } = {}
) => {
  return class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { catchedError: false };
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { catchedError: error };
    }

    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      if (typeof onError === 'function') {
        onError(error, errorInfo);
      }
    }

    render() {
      const { catchedError } = this.state;

      if (catchedError) {
        return <FallbackComponent error={catchedError} />;
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...this.props} />;
    }
  };
};
