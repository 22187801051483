export const isServer = typeof window === 'undefined';
export const isDevelopment = process.env.NODE_ENV === 'development';

export const isObject = (value) => {
  return value !== null && typeof value === 'object';
};

export const isFilledObject = (object) => {
  return isObject(object) && Object.keys(object).length !== 0;
};

export const noop = () => {};

export const once = (fn) => {
  let isComputed = false;
  let result;

  return (...args) => {
    if (isComputed) {
      return result;
    }

    result = fn(...args);
    isComputed = true;

    return result;
  };
};

export const createTrackedValue = ({ onChange = noop, defaultValue = undefined } = {}) => {
  let value = defaultValue;

  const setValue = (newValueGetter, { isSilent = false, additionalOnChangeArgs = [] } = {}) => {
    let newValue;
    if (typeof newValueGetter === 'function') {
      newValue = newValueGetter(value);
    } else {
      newValue = newValueGetter;
    }

    const isValueChanged = newValue !== value;

    if (!isValueChanged) {
      return;
    }

    value = newValue;

    if (!isSilent) {
      onChange(newValue, ...additionalOnChangeArgs);
    }
  };

  return {
    getValue: () => {
      return value;
    },
    setValue,
  };
};
