import { IndexPage } from "@/_pages/IndexPage";
import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";

export const browserRouter = createBrowserRouter([
  {
    path: "/",
    element: <IndexPage />,
  },
  {
    path: "*",
    element: <IndexPage />,
  },
]);

export const Router = () => {
  return <RouterProvider router={browserRouter} />;
};
